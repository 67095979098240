
<template>
  <div>
      <v-card>
        <v-card-title style="word-break: break-word;">
          <div class="text-overline">{{item.title}}<span style="color: red;">{{item.required ? '*' : ''}}</span></div>
        </v-card-title>
        <v-card-text style="word-break: break-word;">
          <div class="text-caption">{{item.description}}</div>
            <v-checkbox
              v-for="(checkbox_item,index) in item.values"
              :value="checkbox_item"
              :label="checkbox_item"
              v-model="checkbox_values"
              :key="index"
              @change="setItem"
            ></v-checkbox>
            <v-text-field @change="setItem" v-if="item.other_value == 'option_and_input'" :disabled="checkbox_values.indexOf('Outro') < 0" color="primary" label="Sua resposta" v-model="other_value_input"></v-text-field>
            <v-input :value="checkbox_values" :rules="item.required ? requiredAtLeastCheckboxItem : []"/>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations';

export default {
  name: 'CheckboxItem',
  mixins: [
    validations
  ],
  props: {
    item: {
      type: Object,
      default: {}
    },
    read_only: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checkbox_values: [],
    other_value_input: null
  }),
  methods: {
    checkOtherValue(){
      if(this.item.other_value != 'none_option' && this.item.values){
        this.item.values.indexOf('Outro', 1) <= 0 ? this.item.values.push('Outro') : ''
      }
    },
    setItem(){
      let new_done_item = {
        index_order: this.item.index_order || 0,
        item_id: this.item.id,
        title: this.item.title,
        answer: this.answer
      }
      let has_item_id = this.done_items_attributes.findIndex(done_item => {
        return done_item.item_id == this.item.id;
      }) < 0 ? false : true
      if(has_item_id){
        this.editInDoneItemsAttributes(new_done_item)
      }else{
        this.addToDoneItemsAttributes(new_done_item)
      }
    },
    ...mapActions({
      addToDoneItemsAttributes: 'DoneForm/addToDoneItemsAttributes',
      editInDoneItemsAttributes: 'DoneForm/editInDoneItemsAttributes'
    })
  },
  computed: {
    answer(){
      let ans = [...this.checkbox_values]
      let outro_idx = ans.indexOf('Outro')
      if (outro_idx >= 0){
        ans[outro_idx] += `: ${this.other_value_input}`
      }
      return ans.join(', ')
    },
    ...mapState({
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  mounted() {
    this.checkOtherValue()
  },
}
</script>

<style scoped>

</style>